import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo/SEO';
import Img from 'gatsby-image';
import styles from './ServiceDetailed.module.scss';
import Layout from '../../components/layout/Layout';

export const query = graphql`
  query {
    fire: file(relativePath: { eq: "explosion/fire.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const AnalysisPage = (props: any) => {
  const { data } = props;
  return (
    <>
      <SEO
        title={
          'Explosion studies. Read about which services we can provide you with in this field.'
        }
      />
      <Layout>
        <div className="grid_text_image grid_vertical_padding sectionShort pb-48 mb-64">
          <div>
            <h1 className={styles.welcomeText}>Explosion studies</h1>
            <p className={styles.descriptionText}>
              Gas explosion studies, evaluating hazards and risk related to indoor or outdoor
              handling of flammable liquids or gases is a main competence. When possible, we
              evaluate the most representative hazard scenarios (e.g. explosions in dispersed
              clouds) and have expertise on how best to model challenging scenarios for accurate
              consequence predictions. By studying the entire chain of events, the most
              cost-effective mitigation measures can be recommended.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.fire.childImageSharp.fluid} alt="Fire fighter" />
            </figure>
          </div>
        </div>
        <div className={`grid_vertical_padding ${styles.articleTitle}`}>Published works:</div>
        <div className={`grid_vertical_padding ${styles.articleContainer}`}>
          <div className={styles.articleColumn}>
          <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.jlp.2014.11.005"
                target="_blank"
                rel="noopener noreferrer"
                title="Improved far-field blast predictions from fast
                deflagrations, DDTs and detonations of vapour clouds using FLACS CFD."
              >
                Hansen, O. R., Johnson, D. M. (2015). Improved far-field blast predictions from fast
                deflagrations, DDTs and detonations of vapour clouds using FLACS CFD.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.jlp.2012.07.006"
                target="_blank"
                rel="noopener noreferrer"
                title="Equivalent cloud
                methods used for explosion risk and consequence studies."
              >
                Hansen, O. R., Gavelli, F., Davis, S. G., Middha, P. (2013). Equivalent cloud
                methods used for explosion risk and consequence studies.
              </a>
            </div>
          </div>
          
          <div className={styles.articleColumn}>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1175/BAMS-87-12-1713"
                target="_blank"
                rel="noopener noreferrer"
                title="Using computational fluid dynamics (CFD) for blast wave predictions."
              >
                Hansen, O. R., Hinze, P., Engel, D., Davis, S. G. (2010). Using computational fluid
                dynamics (CFD) for blast wave predictions.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/profile/Olav_Hansen/publications"
                target="_blank"
                rel="noopener noreferrer"
                title="CFD-based methodology for
                quantitative gas explosion risk assessment in congested process areas: Examples and
                validation status."
              >
                Hansen, O. R., Talberg, O., Bakke, J. R. (1999). CFD-based methodology for
                quantitative gas explosion risk assessment in congested process areas: Examples and
                validation status.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/profile/Olav_Hansen/publications"
                target="_blank"
                rel="noopener noreferrer"
                title="ResearchGate"
              >
                More at ResearchGate
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AnalysisPage;
